import { init, track } from '@amplitude/analytics-browser';
import config from '@src/config';

export const amplitudeInit = (trackPlatform: boolean, trackOsName: boolean): boolean => {
  if (config.AMPLITUDE_API_KEY) {
    init(config.AMPLITUDE_API_KEY, undefined, {
      trackingOptions: {
        deviceManufacturer: false,
        deviceModel: false,
        ipAddress: false,
        language: false,
        osName: trackOsName,
        osVersion: false,
        platform: trackPlatform,
      },
    })
      .promise.then(() => {
        config.AMPLITUDE_INITIALIZED = true;
        return true;
      })
      .catch(() => false);
  } else {
    return false;
  }

  return false;
};

export const amplitudeTrackEvent = (
  eventLabel: string,
  eventProperties?: Record<string, string | number>,
): Record<string, any> | null => {
  if (eventLabel) {
    track(eventLabel, eventProperties)
      .promise.then((response) => {
        return {
          code: response.code,
          finalEvent: response.event,
          message: response.message,
        };
      })
      .catch(() => null);
  }
  return null;
};
