import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { useAuth } from '@src/auth-wrapper';
import LoadingPage from '@components/templates/loading-page';

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const {
    authStatus: { accessToken },
  } = useAuth();

  if (accessToken) return children;
  else return <></>;
};

export default withAuthenticationRequired(PrivateRoute, {
  onRedirecting: () => <LoadingPage />,
});
